import React from 'react';
import './style.css'
import { FiberManualRecord } from '@mui/icons-material';
import { FlexBetween, FlexJustifyCenter } from "../FlexBox";
import { Dialog, DialogContent, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Barcode from 'react-barcode';
import { useTheme } from "@mui/system";
import { toCurrency } from '../../utils';
import { format, utcToZonedTime } from 'date-fns-tz';
const StyledDialogContent = styled('div')({
  padding: 0,
  background:'white',
  width: '950px',
  minWidth:'650px',
  margin:'auto',
  // borderRadius:18
});


const RaffleTicket = ({ticket}) => {

  const {palette} = useTheme();

    const textError = "palette.error.main";
    const textPrimary = "palette.primary.main";
    const textWarning = "palette.warning.main";
    const textInfo = "palette.info.main";
    const textSuccess = "palette.success.main";

  return (
      <StyledDialogContent >
        
        <section id="container" className='ticket'>
      <div className="xs-image-ct" style={{flex:1}}>
      <p className="ticket-number">
        <span style={{marginBottom:16}}>{`Ticket Number :`}</span>
        <span style={{marginTop:8}}>{ticket?.choosen_number}</span>
      </p>
      {/* <img src="https://cdn.pixabay.com/photo/2016/11/18/17/46/house-1836070_1280.jpg" alt=""/> */}
      </div>

      <div className="content" id="ticket-main" style={{flex:3, alignSelf:'center', marginTop:16, flexDirection:'row'}}>
        <div  style={{minWidth:'250px', position:'relative', top:'20%'}}>
        <img style={
          {
            alignSelf:'start',
            width:250
          }}
          src={'/assets/images/logo.png'}
        />
        <p style={{padding:16, background:'hsl(15.25deg 75.73% 53.14%)', border:'1px spolid grey', color:'white',fontWeight:600, fontSize:16, textAlign:'center'}}>
          {format(
            utcToZonedTime(ticket?.raffleGame.created_at , ''),
            'MMMM dd, yyyy '
          )}
        </p>
        </div> 
        <div className='ticket-info'>
            <div className="ticket-main-brand">
              <img style={{ width:'80px'}} src={'/assets/images/bollo-logo.png'} />
            </div>
            <div className='ticket-info-branding'>
            <p style={{color:'white', fontWeight:'600', fontSize:'24px',width:'max-content',textAlign:'center'}}>Win your dream</p>
            <p style={{color:'white', fontWeight:'600', fontSize:'24px',width:'max-content', textAlign:'center'}}>for just {toCurrency('currency', 'USD', ticket?.ticket_price)}</p>
            </div>
          <div style={{textAlign:'end',position:'relative',bottom:'50px',display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row', fontSize:20, marginBottom:26, width:'224px'}}>
          {
            ticket?.raffleGame.title.split(' ').map(item=>{
              return(<h1 style={{textAlign:'end',color:'white', fontWeight:600, marginRight:'1px', marginLeft: '20px'}}>{`${item}`}</h1>)
            })
          }
          </div>
        </div>
        <div className='drawing-info'><p>
For more information: tickets@bol-lo.com </p></div>
      </div>
      <div className='separator'></div>
      <div className="content" id="left" style={{flex:1.5}}>
      <Barcode style={{maxWidth:150}} value={ticket?.barcode || "000000000" }/>
      <FlexBetween style={{position:'relative', top:35}}><Typography sx={{marginBottom:'39px', fontSize:24, fontWeight:800}} color={
                ticket?.status === '2'
                  ? textSuccess
                  : ticket?.status === '1' ? textWarning :
                  ticket?.status === '0' ? textError
                      : textPrimary
              }>{['INVALID','PENDING DRAWING','WON','SYSTEM','SYSTEM'][ticket?.status]}</Typography></FlexBetween>

      </div>
      
    </section>
      </StyledDialogContent>
  );
};

export default RaffleTicket


