import { Box, styled } from "@mui/material";

const FlexBox = styled(Box)(() => ({ display: "flex" }));

const FlexBetween = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
const FlexEnd = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
}));

const FlexAlignCenter = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const FlexAlignCenterdocument = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  borderColor: "primary.main",
  border: 1,
  justifyContent: "center",
  padding: "2px",
}));

const FlexJustifyCenter = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const FlexCenterAll = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
}));

export {
  FlexAlignCenter,
  FlexAlignCenterdocument,
  FlexBetween,
  FlexBox,
  FlexCenterAll,
  FlexEnd,
  FlexJustifyCenter,
};
