import React, { useState, useEffect } from 'react';
import './App.css';
import RaffleTicket from './components/RaffleTicket';

function App() {
  const [tickets, setTickets] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const barcodeParam = urlParams.get('barcode');

  const apiUrl = `https://api.bol-lo.com/api/v1/user-tickets?barcode=${barcodeParam}`;

  useEffect(() => {
    if (!barcodeParam) {
      // If 'barcode' parameter is not present, redirect to bol-lo.com
      window.location.href = 'https://bol-lo.com';
    } else {
      // Fetch tickets
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {

          if (data.result) {

            setTickets(data.result);

          } else {
            window.location.href = 'https://bol-lo.com';
          }
        })
        .catch((error) => {
          window.location.href = 'https://bol-lo.com';
        });
    }
  }, [barcodeParam]);

  return (
    <div className="App">
      {tickets.map((ticket, index) => (
        <React.Fragment key={index}>
          <RaffleTicket ticket={ticket} />
          {index < tickets.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </div>
  );
}

export default App;
